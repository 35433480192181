import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAllButton", "productCheckbox", "errorContainer", "submitButton"]

  connect() {
    // Capture UTM parameters on page load
    this.captureUTMParamsOnce();

    // Apply UTM parameters to the form if the modal opens
    this.applyStoredUTMParamsToForm();
  }

  // Capture UTM parameters from URL and store in localStorage if not already stored
  captureUTMParamsOnce() {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_medium = urlParams.get('utm_medium');
    const utm_source = urlParams.get('utm_source');

    // Only store UTM parameters if present and not already stored
    if (utm_campaign && !localStorage.getItem('utm_campaign')) {
      localStorage.setItem('utm_campaign', utm_campaign);
    }
    if (utm_medium && !localStorage.getItem('utm_medium')) {
      localStorage.setItem('utm_medium', utm_medium);
    }
    if (utm_source && !localStorage.getItem('utm_source')) {
      localStorage.setItem('utm_source', utm_source);
    }
  }

  // Apply UTM parameters from localStorage to the form fields
  applyStoredUTMParamsToForm() {
    const form = document.querySelector('#customHubspotForm');
    if (form) {
      const utm_campaign = localStorage.getItem('utm_campaign') || '';
      const utm_medium = localStorage.getItem('utm_medium') || '';
      const utm_source = localStorage.getItem('utm_source') || '';

      // Debugging logs to ensure UTM values are correct
      console.log('UTM Campaign:', utm_campaign);
      console.log('UTM Medium:', utm_medium);
      console.log('UTM Source:', utm_source);

      // Populate hidden form fields with UTM parameters
      form.querySelector('#utm_campaign__c').value = utm_campaign;
      form.querySelector('#utm_medium__c').value = utm_medium;
      form.querySelector('#utm_source__c').value = utm_source;
    }
  }

  // When the form is submitted, ensure the UTM parameters are present
  async submitForm(event) {
    event.preventDefault();  // Prevent default form submission

    const form = event.target;

    // Validate checkboxes (ensure at least one is checked)
    if (!this.isAtLeastOneChecked()) {
      this.showErrorMessage();
      return;
    }

    // If validation passed, hide the error message
    this.hideErrorMessage();

    try {
      // Use the site key injected from the Rails backend for reCAPTCHA
      const recaptchaToken = await grecaptcha.execute(window.recaptchaSiteKey, { action: 'submit_demo_form' });

      // Add the reCAPTCHA token to the form
      form.querySelector('#recaptchaToken').value = recaptchaToken;

      const formData = new FormData(form);

      // Capture pageUri and pageName and append them to the form data
      const pageUri = window.location.href;
      const pageName = document.title;
      formData.append('pageUri', pageUri);
      formData.append('pageName', pageName);

      const response = await fetch(form.action, {
        method: 'POST',
        headers: { 'X-CSRF-Token': formData.get('authenticity_token') },
        body: formData
      });

      if (!response.ok) throw new Error('Form submission failed.');

      const result = await response.json();
      if (result.status === 'ok') {
        this.loadRevenueHeroWidget(result.hero_data.router_id, result.hero_data.fields);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.productCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.allSelected;
    });

    // Update button text
    this.selectAllButtonTarget.textContent = this.allSelected ? 'Deselect All' : 'Select All';
  }

  // Check if at least one product checkbox is checked
  isAtLeastOneChecked() {
    return this.productCheckboxTargets.some(checkbox => checkbox.checked);
  }

  showErrorMessage() {
    this.errorContainerTarget.classList.remove('hidden');
  }

  hideErrorMessage() {
    this.errorContainerTarget.classList.add('hidden');
  }

  loadRevenueHeroWidget(routerId, fields) {
    const hero = new RevenueHero({ routerId: routerId, showLoader: true });
    hero.submit(fields).then(rhData => {
      hero.dialog.open(rhData);
    }).catch(error => console.error('Error with RevenueHero:', error));
  }
}