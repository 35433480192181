import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slides", "frame", "carousel", "button"]

  connect() {
    this.scrollAmount = 300; // Adjust the scroll amount to your needs

    // Initialize currently focused button to the autofocused button (Time & Attendance)
    this.initializeFocusedButton();

    // Set up event listener to handle focus management
    document.addEventListener('click', this.reapplyFocus.bind(this));
  }

  disconnect() {
    // Clean up event listeners when the controller is disconnected
    document.removeEventListener('click', this.reapplyFocus.bind(this));
  }

  initializeFocusedButton() {
    // Find the button that has the 'autofocus' attribute
    this.currentlyFocusedButton = this.buttonTargets.find(button => button.hasAttribute('autofocus'));

    // If no button has autofocus, fall back to the first button
    if (!this.currentlyFocusedButton) {
      this.currentlyFocusedButton = this.buttonTargets[0];
    }
  }

  selectImage(event) {
    const index = parseInt(event.currentTarget.dataset.index, 10);
    const slideWidth = this.frameTarget.offsetWidth; // Get the width of the visible frame
    const offset = slideWidth * index; // Calculate the offset
    this.slidesTarget.style.transform = `translateX(-${offset}px)`; // Apply the transformation

    // Store the currently focused button
    this.currentlyFocusedButton = event.currentTarget;
  }

  scrollButtonCarousel(event) {
    const direction = event.currentTarget.dataset.direction;
    const carousel = this.carouselTarget;

    if (direction === 'left') {
      carousel.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
    } else if (direction === 'right') {
      // Ensure the carousel doesn't scroll beyond the last element
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const currentScrollLeft = carousel.scrollLeft;

      if (currentScrollLeft < maxScrollLeft) {
        carousel.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
      }
    }
  }

  reapplyFocus(event) {
    // Check if the click occurred within the carousel
    const isClickInsideCarousel = this.carouselTarget.contains(event.target);

    // Check if the click occurred within the modal or the form
    const isClickInsideModal = document.querySelector('#bookingModal')?.contains(event.target);
    const isClickInsideForm = document.querySelector('#customHubspotForm')?.contains(event.target);

    // Reapply focus only if the click is outside both the carousel, the modal, and the form
    if (!isClickInsideCarousel && !isClickInsideModal && !isClickInsideForm) {
      this.currentlyFocusedButton.focus({ preventScroll: true });
    }
  }




  debounce(func, wait, immediate) {
    let timeout;
    return (...args) => {
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(this, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  }
}
